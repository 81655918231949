'use client';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

const units = {
  borderRadius: 0,
  spacing: 12,
};

const colors = {
  white: '#FFFFFF',
  black: '#333333',
  main: '#222222',
  secondary: '#ba7353',
  divider: '#00000015',
  info: '#5B7BA6',
  warning: '#D4925D',
  error: '#C45D5D',
  success: '#5B8A70',
  text: {
    primary: '#1a1a26',
    secondary: '#666666',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  shadow: {
    primary: 'rgba(0, 0, 0, 0.05)',
    secondary: 'rgba(0, 0, 0, 0.04)',
    tertiary: 'rgba(0, 0, 0, 0.03)',
  },
};

const fonts = {
  regular: 'var(--font-regular)',
  weight: {
    regular: 400,
    medium: 500,
    bold: 600,
  },
};

const theme = createTheme({
  colorSchemes: {
    light: {
      palette: {
        contrastThreshold: 4.5,
        common: {
          white: colors.white,
          black: colors.black,
        },
        primary: {
          main: colors.main,
        },
        secondary: {
          main: colors.secondary,
        },
        divider: colors.divider,
        background: {
          default: colors.background.default, // Lighter background for a cleaner look
          paper: colors.background.paper,
        },
        text: {
          primary: colors.text.primary, // Softer text color
          secondary: colors.text.secondary, // Lighter secondary text color
        },
        info: {
          main: colors.info, // More muted blue that complements the theme
        },
        warning: {
          main: colors.warning, // Warmer orange that matches secondary color family
        },
        error: {
          main: colors.error, // Muted red that harmonizes with secondary color
        },
        success: {
          main: colors.success, // Desaturated green that fits the earthy palette
        },
      },
    },
  },
  shape: {
    borderRadius: units.borderRadius,
  },
  spacing: units.spacing,
  shadows: [
    'none',
    `0px 2px 1px -1px ${colors.shadow.primary},0px 1px 1px 0px ${colors.shadow.secondary},0px 1px 3px 0px ${colors.shadow.tertiary}`,
    `0px 3px 1px -2px ${colors.shadow.primary},0px 2px 2px 0px ${colors.shadow.secondary},0px 1px 5px 0px ${colors.shadow.tertiary}`,
    `0px 3px 3px -2px ${colors.shadow.primary},0px 3px 4px 0px ${colors.shadow.secondary},0px 1px 8px 0px ${colors.shadow.tertiary}`,
    `0px 2px 4px -1px ${colors.shadow.primary},0px 4px 5px 0px ${colors.shadow.secondary},0px 1px 10px 0px ${colors.shadow.tertiary}`,
    `0px 3px 5px -1px ${colors.shadow.primary},0px 5px 8px 0px ${colors.shadow.secondary},0px 1px 14px 0px ${colors.shadow.tertiary}`,
    `0px 3px 5px -1px ${colors.shadow.primary},0px 6px 10px 0px ${colors.shadow.secondary},0px 1px 18px 0px ${colors.shadow.tertiary}`,
    `0px 4px 5px -2px ${colors.shadow.primary},0px 7px 10px 1px ${colors.shadow.secondary},0px 2px 16px 1px ${colors.shadow.tertiary}`,
    `0px 5px 5px -3px ${colors.shadow.primary},0px 8px 10px 1px ${colors.shadow.secondary},0px 3px 14px 2px ${colors.shadow.tertiary}`,
    `0px 5px 6px -3px ${colors.shadow.primary},0px 9px 12px 1px ${colors.shadow.secondary},0px 3px 16px 2px ${colors.shadow.tertiary}`,
    `0px 6px 6px -3px ${colors.shadow.primary},0px 10px 14px 1px ${colors.shadow.secondary},0px 4px 18px 3px ${colors.shadow.tertiary}`,
    `0px 6px 7px -4px ${colors.shadow.primary},0px 11px 15px 1px ${colors.shadow.secondary},0px 4px 20px 3px ${colors.shadow.tertiary}`,
    `0px 7px 8px -4px ${colors.shadow.primary},0px 12px 17px 2px ${colors.shadow.secondary},0px 5px 22px 4px ${colors.shadow.tertiary}`,
    `0px 7px 8px -4px ${colors.shadow.primary},0px 13px 19px 2px ${colors.shadow.secondary},0px 5px 24px 4px ${colors.shadow.tertiary}`,
    `0px 7px 9px -4px ${colors.shadow.primary},0px 14px 21px 2px ${colors.shadow.secondary},0px 5px 26px 4px ${colors.shadow.tertiary}`,
    `0px 8px 9px -5px ${colors.shadow.primary},0px 15px 22px 2px ${colors.shadow.secondary},0px 6px 28px 5px ${colors.shadow.tertiary}`,
    `0px 8px 10px -5px ${colors.shadow.primary},0px 16px 24px 2px ${colors.shadow.secondary},0px 6px 30px 5px ${colors.shadow.tertiary}`,
    `0px 8px 11px -5px ${colors.shadow.primary},0px 17px 26px 2px ${colors.shadow.secondary},0px 6px 32px 5px ${colors.shadow.tertiary}`,
    `0px 9px 11px -5px ${colors.shadow.primary},0px 18px 28px 2px ${colors.shadow.secondary},0px 7px 34px 6px ${colors.shadow.tertiary}`,
    `0px 9px 12px -6px ${colors.shadow.primary},0px 19px 29px 2px ${colors.shadow.secondary},0px 7px 36px 6px ${colors.shadow.tertiary}`,
    `0px 10px 13px -6px ${colors.shadow.primary},0px 20px 31px 3px ${colors.shadow.secondary},0px 8px 38px 7px ${colors.shadow.tertiary}`,
    `0px 10px 14px -6px ${colors.shadow.primary},0px 22px 35px 3px ${colors.shadow.secondary},0px 8px 42px 7px ${colors.shadow.tertiary}`,
    `0px 11px 14px -7px ${colors.shadow.primary},0px 23px 36px 3px ${colors.shadow.secondary},0px 9px 44px 8px ${colors.shadow.tertiary}`,
    `0px 11px 15px -7px ${colors.shadow.primary},0px 24px 38px 3px ${colors.shadow.secondary},0px 9px 46px 8px ${colors.shadow.tertiary}`,
    `0px 11px 15px -7px ${colors.shadow.primary},0px 24px 38px 3px ${colors.shadow.secondary},0px 9px 46px 8px ${colors.shadow.tertiary}`,
  ],
  breakpoints: {
    values: {
      xxxl: 1920,
      xxl: 1536,
      xl: 1440,
      lg: 1280,
      md: 1024,
      sm: 600,
      xs: 0,
    },
  },
  typography: {
    fontFamily: fonts.regular,
    fontWeightRegular: fonts.weight.regular,
    fontWeightMedium: fonts.weight.medium,
    fontWeightBold: fonts.weight.bold,
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2.25rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
    },
    button: {
      '& span': {
        fontWeight: 'bold',
      },
    },
    body1: { lineHeight: 1.5 },
    body2: { lineHeight: 1.5 },
    caption: { lineHeight: 1.5 },
    overline: { lineHeight: 1.5 },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMore />,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 4,
        variant: 'elevation',
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: 0,
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        slotProps: {
          primary: {
            variant: 'h6',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          position: 'relative',
          transition: 'all 0.2s ease-in-out',
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 0,
            borderRadius: 'inherit',
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
            background:
              'radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%)',
            pointerEvents: 'none',
          },
          '&:active::after': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        IconComponent: ExpandMore,
      },
      styleOverrides: {
        root: {
          borderRadius: units.borderRadius,
          fontSize: '0.875rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: 42,
          height: 26,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: theme.palette.secondary.main,
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            borderRadius: 12,
          },
          '& .MuiSwitch-track': {
            borderRadius: 12,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
        variant: 'elevation',
      },
      styleOverrides: {
        root: {
          borderRadius: units.borderRadius,
          backgroundColor: '#00000007',
          padding: units.spacing * 2,
          boxShadow: 'none',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'standard',
        slotProps: {
          inputLabel: {
            shrink: true,
          },
        },
      },
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          fontWeight: fonts.weight.medium,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: units.borderRadius,
          fontWeight: fonts.weight.medium,
        },
        sizeSmall: {
          whiteSpace: 'nowrap',
        },
        contained: {
          boxShadow: `0px 1px 3px rgba(0, 0, 0, 0.1)`,
        },
        outlined: {
          boxShadow: 'none',
        },
      },
    },
    MuiPopover: {
      defaultProps: {},
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', // Added subtle shadow
            backgroundColor: colors.background.paper,
            border: '1px solid',
            borderColor: colors.divider,
            borderRadius: units.borderRadius,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
    },
  },
});

const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
